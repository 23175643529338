
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const Calender = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Academic Calendar" pageName="Academic Calendar" mid="Academics" />
            <div className="innerSection">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className='inner_title'>Academic Calendar</h3> 
                        </div>
                        <div className='col-lg-12'>
                            <iframe src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/pdf/Academic-Calendar.pdf" height="800" width="100%"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Calender