
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const Motto = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Motto" pageName="Motto" mid="About" />
            <div className="innerSection">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/motto.jpg" className='img-fluid border_img' alt="Little Flower School" />
                        </div>
                        <div className='col-lg-7 overlap'>
                            <h3 className='inner_title'>Our Motto</h3>
                            <p>FOR God and Country through the Values of LOVE, JUSTICE and SERVICE for this we:</p>
                            <ul>
                                <li>Recognize and encourage talents and foster an appreciation of creative ability of every child.</li>
                                <li>Stimulate and satiate intellectual curiosity.</li>
                                <li>Develop in the school a caring community, exercising concern and respect for the welfare of others</li>
                                <li>Encourage children to appreciate the virtues of collaboration and initiative.</li>
                                <li>Offer a wide variety of high quality education and learning opportunities for children.</li>
                            </ul>
                            <p><b>FOR God and Country through the Values of LOVE, JUSTICE and SERVICE</b></p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Motto