import React from 'react'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
import { Link } from 'react-router-dom'
const ParentPortal = () => {
  return (
    <>
      <Header />
      <Breadcrumb title="Parent Portal " pageName="Parent Portal " mid="Download" />
      <div className="innerSection">
        <div className="container">
          <div className="bgimage">
          <h3 className='inner_title'> Parent Portal Guidelines</h3>  
          </div>

          <div className="erp_sec">

            <p className="guideheading">CampusCare®  Application Guidelines &amp; Instructions </p>
            <div className="clr10"></div>
            <p className="guideheading"><strong>How to LOG IN Parent Portal?</strong></p>
            <div className="clr10"></div>
            <p className="details"> 1. Open Chrome<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/google.png" className="google_img icon_pp" width="68" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" /> OR Mozilla <img className="mozilla_img icon_pp" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/mzilla.png" width="60" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" /> or Internet <img className="internet_img icon_pp_inter" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/internet.png" width="18" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" />  </p>
            <p className="details"> 2. Write the school URL <strong><Link target="_blank" to="https://www.lfscampuscare.org/">"lfscampuscare.org"</Link></strong> in address bar.
              (you will be routed to the Log In credential page).</p>
            <p className="details"> 3. Then, enter the USER ID and Click on  "Next button", Then enter
              PASSWORD and Click on Sign in  button.</p>
            <p className="details">Once logged in, you are welcomed with various information
              relating to assignments, news, circulars, date sheet, fee details etc. that
              are self explanatory. Wish you to go through every links that are
              provided and explore more.</p>

            <div className="clr10"></div>
            <p className="guideheading"><strong>How to RESET PASSWORD in Parent Portal? </strong></p>
            <div className="clr10"></div>
            <p className="details"> 1. Open Chrome <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/google.png" className="google_img icon_pp" width="68" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" /> OR Mozilla <img className="mozilla_img icon_pp" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/mzilla.png" width="60" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" /> or Internet <img className="internet_img icon_pp_inter" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/internet.png" width="18" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" />  </p>
            <p className="details"> 2. Write the school URL <strong><Link target="_blank" to="https://www.lfscampuscare.org/">"lfscampuscare.org"</Link></strong> in address bar.
              (you will be routed to the Log In credential page).</p>
            <p className="details">3. Then, click on “Forgot User ID or Password?” text given below User ID field.</p>
            <p className="details">4. Select the User Type (For which you want a password) then Enter Registered Mobile No. and click on “Reset” Button.</p>
            <p className="details">5. You will get an SMS on your registered mobile no. along with login credentials.
            </p>
            <div className="clr10"></div>
            <div>
              <p className="guideheading"><strong> How to Install &amp; operate MOBILE APP? </strong></p>
              <p>1. Go to <img className="download_img icon_pp_dwn" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/playstore.png" width="79" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" />  Store / <img className="download_img icon_pp_dwn" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/apple.png" width="89" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" />  and Search

                "CampusCare®". </p>
              <p> 2. Install and open the <Link to="https://www.entab.in/mobile-apps.aspx"><b>Mobile App</b></Link>.</p>
              <p>3. Enter school web portal URL: <strong><Link target="_blank" to="https://www.lfscampuscare.org/">"lfscampuscare.org"</Link></strong> in link bar      &amp; verify. </p>
              <p>4. After verifying, you will get an option for User ID &amp; Password.</p>
              <p>Once logged in, you are welcomed with various information relating to assignments, news. circulars, date sheet, fee details etc that are self Explanatory. Wish you to go through every links that are

                provided and      explore more. </p>
              <p><strong>NOTE* :</strong> Kindly update your <Link to="https://www.entab.in/mobile-apps.aspx"><b>Mobile Apps</b></Link> time to time to avoid difficulties during usage.</p>
            </div>
            <div className="clr10"></div>
            <div>
              <p className="guideheading"><strong>How to make Online Payment? :-</strong> </p>
              <p> 1. Open Google Chrome <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/google.png" className="google_img icon_pp" width="68" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" /> / Mozilla Firefox <img className="mozilla_img icon_pp" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/mzilla.png" width="60" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" /> or Internet Explorer <img className="internet_img icon_pp_inter" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/internet.png" width="18" height="20" layout="responsive" alt="School ERP Software, Mobile App, School Management Software" /> </p>
              <p> 2. Enter the school URL <strong><Link target="_blank" to="https://www.lfscampuscare.org/">"lfscampuscare.org"</Link></strong> address bar and you will be routed to the Log In page.</p>
              <p> 3. Now, enter the USER ID and then enter your PASSWORD and Click on ‘Sign in’ button.</p>
              <p> 4. After login, click on the Online Payment menu.</p>
              <p> 5. Select the installment to pay &amp; click on the proceed button.</p>
              <p> 6. Now, you will be redirected to Payment Gateway page.</p>
              <p> 7. Choose the Pay mode : Credit Card/Debit Card/Net banking &amp; fill the details to complete the payment process.</p>
              <p> 8. After successful payment, you will receive the fee receipt &amp; SMS on successful fee payment.</p>
              <div className="clr25"></div>
              <p><b>Note :-</b><i> If for any reason, the fee payment output details are not displayed on your screen after you have made payments, please check the details in “My Payment” in Finance link. If your accounts get debited/deducted and receipt is not available, you are advised to contact school office or mail us.</i></p>
            </div>


            <div className="clr10"></div>
            <div className="assistance">
              <h3 className="sub_title">Please call or write for assistance:</h3>
              <p className="details"><strong> <Link to="https://www.entab.in/contact-us.aspx">PARENT HELP DESK</Link></strong> ( 8 AM to 8:00 PM ) - <strong>Tele No.</strong> 011- 43193333 - Ext : 5,<strong> E-Mail :</strong> <strong><Link to="mailto:parentdesk@entab.in" className="yellow-link"> parentdesk@entab.in</Link></strong></p>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ParentPortal
