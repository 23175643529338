import { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import Slider from "react-slick";
const TopperX = () => {
  const [classXData, setClassXData] = useState([]);

   
  useEffect(() => {
      const fetchData = async () => {
          const topperData = await getTopper();
          const currentYear = new Date().getFullYear();
          const yearString = `${currentYear - 1}-${currentYear}`;
          
          const classX = topperData.filter((item) => item.class === 'X' && item.sessionYear === yearString); // Adjust the filtering condition as needed
          setClassXData(classX); 
          
          console.log("Filtered class X data for year", yearString, classX);
      };
      fetchData();
  }, []);

 



  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <>
      <div className="row">
        <Slider {...settings}>
        {classXData.length>0 ? (classXData.map((item1, index) => (
          <div className="item" key={index}>
            <div className="topperdiv">
              <div className="toppertaj">
                <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item1.attachments}`} className="img-fluid" />
              </div>

              <div className="topperdesc">
                <p className="name">{item1?.name}<span>{item1?.stream}</span></p>
                <p className="per">{item1?.division}</p>
              </div>
            </div>
          </div>))): (
            <div className="item">
            <div className="topperdiv">
              <div className="toppertaj">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/topper.jpg" className="img-fluid" />
              </div>

              <div className="topperdesc">
                <p className="name">Student Name<span>Stream</span></p>
                <p className="per">Marks</p>
              </div>
            </div>
          </div>
          )}
          {/* <div className="item">
            <div className="topperdiv">
              <div className="toppertaj">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/topper.jpg" className="img-fluid" />
              </div>

              <div className="topperdesc">
                <p className="name">Sushant prakash<span>science</span></p>
                <p className="per">96.4%</p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="topperdiv">
              <div className="toppertaj">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/topper.jpg" className="img-fluid" />
              </div>

              <div className="topperdesc">
                <p className="name">Sushant prakash<span>science</span></p>
                <p className="per">96.4%</p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="topperdiv">
              <div className="toppertaj">
                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/topper.jpg" className="img-fluid" />
              </div>

              <div className="topperdesc">
                <p className="name">Sushant prakash<span>science</span></p>
                <p className="per">96.4%</p>
              </div>
            </div>
          </div> */}

        </Slider>
      </div>
    </>
  )
}

export default TopperX