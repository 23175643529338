import { Link } from 'react-router-dom'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const Result = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Result & Recheck" pageName="Result & Recheck" mid="Academics" />
            <div className="innerSection result-img">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className='inner_title'>Result & Recheck</h3>
                        </div>
                        <div className='col-lg-4'>
                            <div className='result_blk'>
                                <h3 className='sub_title'><Link to="https://results.cisce.org//" target="_blank">ICSE / ISC Results 2018</Link></h3>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/result.jpg" className='img-fluid border_img' alt="Little Flower School" />
                        </div>
                        <div className='col-lg-4'>
                            <div className='result_blk'>
                                <h3 className='sub_title'><Link to="https://cisce.org/RecheckInstruction.aspx/" target="_blank">ICSE / ISC apply for Recheck Online</Link></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Result