
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const Aims = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Aims & Objectives" pageName="Aims & Objectives" mid="About" />
            <div className="innerSection">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/aims.jpg" className='img-fluid border_img' alt="Little Flower School, Gita Vatika" />
                        </div>
                        <div className='col-lg-7 overlap'>
                            <h3 className='inner_title'>Aims & Objective</h3>
                            <p>At Little Flower School we embark on the mission of:</p>
                            <ul>
                                <li>Integral development – intellectual, mental and spiritual – of every child enrolled here.</li>
                                <li>To make them responsible, broad-minded, patriotic and disciplined citizen of the country.</li>
                                <li>To make our children committed to the service of God and of the Nation.</li>
                                <li>To instill into them a spirit of compassion, unselfish service, dedication to duty, Love for the fellow men and thirst for excellence and wisdom.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Aims