import React from 'react'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
import { Link } from 'react-router-dom'
const ERPSoftware = () => {
     return (
          <>
               <Header />
               <Breadcrumb title="ERP Software" pageName="ERP Software" mid="SCHOOL ONLINE" />
               <div className="innerSection">
                    <div className="container">

                         <div className="innerpage">
                              <div className="row erp_sec">
                                   <div className="col-12 col-sm-12 col-md-9">
                                        <div className="row">
                                             <div className="col-12 col-sm-12 col-md-12">
                                                  <p className="details"> Entab Infotech Pvt Ltd., a company formed in 2000 and serving the school with automation technologies has evolved out as the best service provider through their advanced <Link to="https://www.entab.in/school-management-erp.html"> <b>ERP Software:</b></Link> 'CampusCare®' and communication tools. We are also proud to be associated with them for managing the data of students, exam, fees, staff and  their accounts etc in addition to the <Link to="https://www.entab.in/school-communication-portals.html"> <b> communication portal</b></Link> and <Link to="https://www.entab.in/mobile-apps.html"> <b> mobile apps</b></Link>.<br />
                                                       <br />
                                                       The entire system is strengthening the academic of the students and preparing responsible staff and students. Together the reputation of the school grows higher. We value the relation with  <Link to="https://www.entab.in/"> <b>Entab: CampusCare®</b></Link> and look forward to receive the much more in technological front.</p>
                                             </div>
                                        </div>
                                        <div className="clr25"></div>
                                        <div className="row">

                                             <div className="col-12 col-sm-4 col-md-4">
                                                  <h3 className="imgheading text-center sub_title"><Link to="https://www.entab.in/school-management-erp.html"> Manage Data</Link></h3>

                                                  <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/Manage-Data.gif">
                                                       <img id="myImg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/Manage-Data.gif" className="img-fluid border_img" alt="School Management System, Management ERP Software" /></a>


                                             </div>
                                             <div className="col-12 col-sm-4 col-md-4">
                                                  <h3 className="imgheading text-center sub_title"><Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/Parent-Portal.gif"> Parent Portal</Link></h3>

                                                  <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/Manage-Data.gif">
                                                       <img id="myImg1" lightbox src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/Parent-Portal.gif" className="img-fluid border_img" alt="Entab Parent Portal, School Software" />
                                                  </a>

                                             </div>
                                             <div className="col-12 col-sm-4 col-md-4">
                                                  <h3 className="imgheading text-center sub_title">
                                                       <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/communication.gif"> Communication Portal</Link></h3>
                                                  <a data-magnify="gallery" data-src="" data-group="a" href="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/Manage-Data.gif">
                                                       <img id="myImg2" src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/communication.gif" className="img-fluid border_img" alt="Entab Parent Portal" /></a>

                                             </div>
                                        </div>
                                   </div>
                                   <div className="col-12 col-sm-12 col-md-3">
                                        <h3 className="imgheading text-center sub_title"><Link to="https://www.entab.in/mobile-apps.html">CampusCare</Link></h3>
                                        <center><Link to="https://www.entab.in/mobile-apps.html">
                                             <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/mobile.png" className="img-fluid" alt="School ERP Software, Mobile App, School Management Software, Software for School" /></Link> </center>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <Footer />
          </>
     )
}

export default ERPSoftware
