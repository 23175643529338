import React from 'react';
import { Link } from 'react-router-dom'; 
  
const Footer = () => { 
    return (
        <>
            <div className="footer padded-sec">
                 <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='ftr-logo'>
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/ftr-logo.png" alt="Little Flower School" />
                        <ul>
                          <li><Link to="https://www.facebook.com/littleflowerschoolgkp" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/facebook.png" alt="Little Flower School" /></Link></li>
                          <li><Link to="https://www.youtube.com/channel/UCucCBCEgweiPSBdphRi0GPw" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/youtube.png" alt="Little Flower School" /></Link></li>
                          <li><Link to="https://www.instagram.com/lfsgkp/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/insta.png" alt="Little Flower School" /></Link></li>
                        </ul>
                      </div>
                    </div>                    
                    <div className='col-md-6'>
                      <div className="mobile-app">
                        <div>
                          <p><b>Download Mobile App </b>A Common Platform For <b>Educators, Parents & Teachers</b></p>
                          <div className='mobile-link'>
                            <Link to="https://apps.apple.com/in/app/campuscare/id1034721587" target='_blank'><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/app-store.png" alt="Little Flower School" /></Link>
                            <Link to="https://play.google.com/store/apps/details?id=com.campuscare.entab.ui" target='_blank'><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/playstore.png" alt="Little Flower School" /></Link>
                          </div>
                          <p className='code'>Use School Code : lfscampuscare.org</p>
                        </div>
                        <div>
                          <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/mobile.png' alt="Little Flower School" />
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
            </div>
            <div className='copyright'>
            ©️Copyright 2024 LFS, GKP All Rights Reserved | Created by Entab Infotech : CampusCare®️  
            </div>
        </>
    );
}

export default Footer;
