
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const FeeStructure = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Examination & Promotion" pageName="Examination & Promotion" mid="Academics" />
            <div className="innerSection">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className='inner_title'>Examination & Promotion</h3> 
                        </div>
                        <div className='col-lg-5'>                            
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFSGV/public/Images/exam.jpg" className='img-fluid border_img' alt="Little Flower School, Gita Vatika" />
                        </div>
                        <div className='col-lg-7'>
                            <p>Annual promotions are decided through a system of continuous assessment throughout the year.</p>
                            <p>The promotion of a student will be on aggregate basis:</p>
                            <p>The marks obtained in the Annual Examinations alone will not determine the promotion of the student. The total marks are divided among the <b>monthly tests, Half Yearly and Annual Examinations.</b></p>
                            <p>If any student does not show steady and consistent progress in studies, he/she is not liable to be promoted. There will be no re-examination or examination out of schedule. For being promoted a student must secure a <b>minimum of 40%</b>  in each subject and a total <b>aggregate of 50%</b>. No leave other than Medical Leave will be sanctioned during the examination; <b>Medical Leave</b> is to be applied for when the leave is taken and supported by Medical Certificate.</p>
                            <p>A Fitness Certificate also must be produced when the student attends the class next. However, he/she will have to forgo the marks in the aggregate. Students detected in giving or obtaining or attempting to give or obtain unfair assistance at the examinations will be disqualified. Students shall not take any books, notes, papers etc. into examination hall. All cases of doubt regarding evaluation of answer papers and promotion test rests entirely at the discretion of the Principal. A student who fails for two consecutive years in a class will automatically be disqualified for continuance of studies in this school.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default FeeStructure